<template>

    <div v-if="luckyDraws.data && luckyDraws.data.length" style=" width: 100%;
                height: 100%;" :style="{background: `url('/img/background_luckzone${w > 600 ? '' : '_mb'}.png'`, 'background-repeat': 'no-repeat !important', 'background-size': 'cover !important', }">
      
      
               
            <div class="text-center text-h4 white--text" :style="{marginTop: w > 600 ? '10px' : '100px'}">
                {{title}}
            </div>
            <div class="text-center text-h6 white--text" >
                Quay 100% là trúng
            </div>

            <div class="text-center white--text">
                Bạn còn {{wheel_count}} lượt quay
            </div>

        <div v-if="luckyDraws && luckyDraws.data && luckyDraws.data.length">
    
            <div v-if="w > 600" style="margin-left: calc(50% - 350px);">
                <WheelPC :luckyDraws="luckyDraws" :startWheel="startWheel" :reward_id="reward_id" @got_wheel="gotWheel()">
                    
                </WheelPC>
            </div>
            
            <div v-else style="margin-left: calc(50% - 150px);">
                <wheel-mobile :luckyDraws="luckyDraws" :startWheel="startWheel" :reward_id="reward_id" @got_wheel="gotWheel()"></wheel-mobile>
            </div>
        </div>

        <v-dialog v-model="dialog_phone" persistent max-width="700">
            <v-card>
                <v-card-title>
                    {{is_got_wheel ? 'Danh sách đơn hàng của bạn' : 'Điền số điện thoại đã mua hàng & đánh giá 5* để nhận lượt quay'}}
                </v-card-title>
                <slot v-if="!is_got_wheel">
                    <v-card-text class="text--primary">
                        <div class="red--text h6 mb-4">
                            {{message}}
                        </div>
                        <v-text-field label="Số điện thoại" outlined v-model="phone"></v-text-field>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="orange" :loading="is_get_wheel" block @click="getWheel()">Nhận lượt quay</v-btn>
                    </v-card-actions>
                </slot>
                <slot v-else>
                    <v-card-text class="text--primary">
                        <div class="red--text h5 mb-4" v-if="wheel_count">
                            Chúc mừng bạn nhận được {{wheel_count}} lượt quay.
                        </div>
                        <div v-for="item, index in orders">
                            <div> {{index + 1}}) Đơn hàng: {{item.order_id}} </div>
                            <div><label v-html="item.id_data.message"></label></div>
                            <div v-if="item.wheel_data">
                                Phần thưởng của bạn là: {{item.wheel_data.text}}
                                
                            </div>
                        </div>
                    </v-card-text>
                    <v-card-actions v-if="wheel_count">
                        <v-btn color="red" block @click="dialog_phone = false;">Đóng</v-btn>
                    </v-card-actions>
                </slot>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialog_reward" max-width="600">
            <v-card v-if="reward_data">
                <v-card-text class="text--primary">
                    <div class="text-center" style="padding-top: 30px;">
                        Xin chúc mừng bạn nhận được
                    </div>
                    <div class="text-center orange--text">
                        {{reward_data.title}}
                    </div>
                    <div class="text-center">
                        Hãy chat với shop và cung cấp mã đơn hàng {{reward_data.order_id}} để nhận thưởng nhé. 
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-btn block color="red" @click="dialog_reward = false;">Đóng</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- <iframe src="/img/blackjack1.mp3" allow="autoplay" style="display:none" id="iframeAudio">
        </iframe>  -->

    </div>
</template>

<script>

import WheelPC from '@/components/WheelPC.vue';
import WheelMobile from '@/components/WheelMobile.vue';
import axios from 'axios';
var vm;

export default {
    components: {WheelPC, WheelMobile},
    data() {
        return {
            w: screen.width,
            title: 'VÒNG QUAY MAY MẮN',
            luckyDraws: [],
            dialog_phone: true,
            dialog_reward: false,
            wheel_count: 0,
            phone: '',
            reward_id: null,
            is_get_wheel: false,
            is_got_wheel: false,
            orders: [],
            message: '',
            reward_data: null,
            music: null,
            apiHost: 'easysell.vn'
        }
    },

    mounted() {
        
    },

    created() {
        vm = this;
        vm.getDrawLucky();
        document.title = "Vòng quay may mắn - Quay là trúng";
        window.addEventListener("DOMContentLoaded", event => {
            const audio = document.querySelector("audio");
            audio.volume = 0.2;
            audio.play();
        });
    },

    
    methods: {
        getDrawLucky() {
           axios.get(`https://${vm.apiHost}/api/lucky-draw/get?id=${vm.$route.query.id}`)
            .then(function(data) {
                vm.luckyDraws = data.data.data;
                vm.title = data.data.data.title;
                // vm.music = new Audio('/img/blackjack1.mp3'); 
                // vm.music.addEventListener('loadeddata', () => {
                //     vm.music.play()
                // })
                vm.$forceUpdate();
            })
        },
        getWheel() {
            vm.is_get_wheel = true;
            axios.post(`https://${vm.apiHost}/api/lucky-draw/get-wheel`, {
                phone: '84' + vm.phone,
                user: vm.luckyDraws.user
            })
            .then(function(res) {
                vm.is_get_wheel = false;
                if (res.data.data && res.data.data.success) {
                    vm.wheel_count = res.data.data.wheel;
                    vm.is_got_wheel = true;
                    vm.orders = res.data.data.orders;
                }
                else {
                    vm.message = res.data.message
                }
                
            })
        },

        startWheel() {
            if (!vm.wheel_count) return false;
            axios.post(`https://${vm.apiHost}/api/lucky-draw/start-wheel?id=${vm.$route.query.id}`, {
                phone: '84' + vm.phone,
                user: vm.luckyDraws.user
            })
            .then(function(res) {
                if (res.data.success === false) {
                    alert(res.data.message);
                    location.href = location.href;
                    return;
                }
                if (res.data.data && res.data.data.success === false) {
                    alert(res.data.data.message);
                    location.href = location.href;
                    return;
                }
                vm.reward_data = res.data.data;
                vm.reward_id = res.data.data.id;
                
                vm.$forceUpdate();
            });
            return true;
        },

        gotWheel() {
            vm.dialog_reward = true;
            vm.wheel_count = vm.wheel_count - 1;
        }
    },

    watch: {
        
    }
}
</script>
