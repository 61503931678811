<template>
<div>
    asdasdsa
  <v-app-bar app elevate-on-scroll color="white" style="z-index: 9999">
      <v-app-bar-nav-icon @click="drawer = true;" class="d-md-none d-block"></v-app-bar-nav-icon>
      <v-img  style="margin-left: 60px;" src="/img/easysell.png" contain max-width="300" max-height="60">
      </v-img>
      <v-spacer></v-spacer>
      <div class="menu-item d-md-block d-none" v-for="item, index in menus" :key="index">
        <div @click="$vuetify.goTo(item.hash, {duration: 1000, offset: 20})" :class="`#${hash}`== item.hash ? 'active-exact-link' : ''">{{item.text}}</div>
      </div>
  </v-app-bar>
  <v-navigation-drawer app fixed style="z-index: 9999" v-model="drawer" temporary
    >
    <v-img src="/img/easysell.png"></v-img>
    <v-divider></v-divider>ff
    <v-list dense>
      <v-list-item v-for="item, index in menus" :key="index">
        <v-list-item-content>
          <div @click="$vuetify.goTo(item.hash, {duration: 1000, offset: 20})" :class="`#${hash}`== item.hash ? 'active-exact-link' : ''">{{item.text}}</div>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>


  <v-content >
    <v-container>
      <div class="page 1" id="home" style="max-width: 600px" >
        <img :style="{width: size.x < 1000 ? '100%' : '676px'}" style="position: absolute; left: -300px; top: 60px;" src="/img/elipse.png"/>
        <img class="d-none d-md-block" style="position: absolute; right: 0px; top: 100px;" :style="{width: setWidthTopImage() + 'px'}" src="/img/infor.png"/>
        <div class="d-none d-md-block" style="margin-top: 80px;"></div>
        <div class="page-1-content" style="position: relative; margin-left: 50px;">
          <div class="text-center">
            <img src="/img/logox192.png" style="margin-bottom: 20px"/>
          </div>
          <div class="text" style="padding-left: 30px">
            <div class="main-title text-center" style="margin-top: -20px;">
              Phần mềm quản lý bán hàng đa kênh
            </div>
            <div style="font-size: 20px;" class="mt-5">
              - Quản lý sản phẩm & đơn hàng trên nhiều nền tảng cùng một lúc
            </div>
            <!-- <div style="font-size: 20px" class="mt-2">
              - Hãy để chúng tôi giúp bạn xua tan nỗi lo Tài Chính, Mất Hàng, Kiểm Soát Hàng Hóa và Chăm Sóc Khách Hàng.
            </div> -->
            <div class="button mt-3 text-center">
              <v-btn style="width: 314px;" class="fade-in secondary mr-5 mt-2" rounded href="https://easysell.vn/admin">Sử dụng ngay</v-btn>
            </div>
          </div>
        </div>
      </div>
      <div class="d-none d-xl-block" style="margin-top: 100px;"></div>

  
      <!-- <div class="page 3" id="vision" style="position: relative;">
        <img style="position: absolute; top: -160px; right: 100px;" :style="{width: size.x > 1000 ? '414px' : '100%'}" src="/img/elipse1.png"/>
        <div style="margin-top: 500px" class="d-block d-lg-none"></div>
        <div style="margin-top: 200px" class="d-none d-lg-block"></div>
        <div class="main-title text-center">Tầm nhìn phát triển</div>
        <div class="text-description mt-5 text-center" >
          Easysell sẽ luôn vươn mình để trở thành ứng dụng tốt nhất dẫn dầu xu thế số, góp phần tạo ra sản phẩm<br/> giá trị cho xã hội, giải quyết các bài toán về nhu cầu của người kinh doanh trong thời đại số hoá.<br/>
          Để xứng đáng với cái tên <strong class="primary--text">EasySell – Bán hàng chỉ còn là chuyện nhỏ.</strong>
        </div>
        <v-container>
          <div class="text-center">
            <img src="/img/baomatdulieu.png" style="width: 100%"/>
          </div>
        </v-container>
      </div> -->


      <!-- <div class="comment" style="position: relative; overflow: hidden">
        <img style="position: absolute; top: 30px; right: -300px;" :style="{width: size.x > 1000 ? '500px' : '100%'}" src="/img/elipse.png"/>
        
        <slot v-for="item, index in comments">
          <div :style="{display: index == active_comment ? 'block' : 'none', width: size.x > 1000 ? '900px': '100%', marginLeft: size.x > 1000 ? 'calc(50% - 450px)' : '0'}" class="cmt-animation">
            <v-img src="/img/bgcomment.png"  class="text-center ">
              <v-avatar :size="220" style="margin-top: 120px">
                <v-img class="fade-in" :src="item.avatar" ></v-img>
              </v-avatar>
              <div class="primary--text" style="font-size: 28px">{{item.name}}</div>
              <strong class="text-description">{{item.position}}</strong>
              
            </v-img>
            <div :style="{width: size.x > 1000 ? '850px' : '100%', marginLeft: size.x > 1000 ? 'calc(50% - 450px)' : '0'}" class="text-description text-center mt-5 fade-in">
              <div style="" v-html="item.cmt">
              </div>
            </div>
          </div>
        </slot>

        <div class="text-center mt-5">
          <img class="pointer" :src="active_comment == 0 ? '/img/tab_active.png' : '/img/tab_inactive.png'" @click="active_comment = 0"/>
          <img class="pointer" :src="active_comment == 1 ? '/img/tab_active.png' : '/img/tab_inactive.png'" @click="active_comment = 1"/>
          <img class="pointer" :src="active_comment == 2 ? '/img/tab_active.png' : '/img/tab_inactive.png'" @click="active_comment = 2"/>
        </div>
      </div> -->
    </v-container>
  </v-content>
    
  <div id="contact" class="footer" style="position: relative; margin-top: 100px">
    
    <div class="text-center">
      <img class="pointer" src="/img/btn_to_top.png" style="z-index: 99; top: -50px; left: 50%; position: absolute; transform: translate(-50%);" @click="$vuetify.goTo(0, {duration: 1000})"/>
    </div>


    <v-img src="/img/bgfooter.png">
      <v-container fluid>
        <v-row>
          <v-col md="6" cols="12">
            <img style="margin-top: 100px; margin-left: 10px" src="/img/icon_other.png" :style="{width: size.x > 1000 ? '' : '100%'}"/>
            <h3 class="white--text" style="font-size: 40px; font-weight: 500; margin: -20px 0px 0px 30px;">
              Bán hàng chỉ còn là chuyện nhỏ <img src="/img/bct.png"/>
            </h3>
            <div style="margin: 30px 0px 0px 30px">
              <img src="/img/www.png"/>
              <img src="/img/fb.png"/>
              <img src="/img/yt.png"/>
            </div>
            <div style="margin: 40px 0px 0px 30px">
              <v-btn rounded class="white primary--text mt-2">Xem cách sử dụng</v-btn>
            </div>
          </v-col>
          <v-col md="6" class="d-none d-lg-block">
            <v-card style="border-radius: 25px; padding: 15px; margin-top: 120px; width: 95%">
              <v-card-text>
                <v-row>
                  <v-col cols="4">
                    <v-text-field outlined label="Nhập họ tên"></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field outlined label="Nhập số điện thoại"></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field outlined label="Nhập Email"></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea outlined label="Nhập tin nhắn"></v-textarea>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <strong class="text-description">Chúng tôi sẽ sớm phản hồi lại cho bạn!</strong>
                <v-spacer></v-spacer>
                <v-btn class="primary">Gửi đi</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <v-divider class="white mt-3"></v-divider>
        <div class="d-flex white--text">
          <div style="font-size: 20px; margin: 8px" class="d-none d-lg-block">Hotline: 033.9886.294</div>
          <v-spacer></v-spacer>
          <label style="font-size: 20px; margin: 8px">Copyright © 2021 EasySell. All rights reserved.</label>
        </div>
      </v-container>
      
    </v-img>
  </div>
</div>
</template>

<script>
var timeout;
  export default {
    head() {
      return {
        bodyAttrs: {
          class: ''
        }
      }
    },
    data () {
      return {
        menus: [{text: 'TRANG CHỦ', hash: '#home'}, {text: 'TẦM NHÌN', hash: '#vision'}, {text: 'LIÊN HỆ', hash: '#contact'}],
        comments: [{avatar: 'https://scontent.fsgn6-1.fna.fbcdn.net/v/t39.30808-6/243301381_276960317487593_736062857357338834_n.jpg?_nc_cat=109&ccb=1-5&_nc_sid=09cbfe&_nc_ohc=1QquNtb1_hoAX9kQEL_&_nc_ht=scontent.fsgn6-1.fna&oh=e9e4cdd5440fe87a210a910b3b98bf1b&oe=61650373', name: 'Khải lê', position: 'CEO Đức Phúc Fashion', cmt: 'Ứng dụng chuyên nghiệp, hỗ trợ hầu hết mối lo mất hàng trước đây, ứng dụng cũng rất tiện ích. Tôi rất hài lòng về ứng dụng này, mong ứng dụng ngày càng phát triển hơn nữa.'}, {avatar: '/img/avatar.png', name: 'Nguyễn Huỳnh Anh', position: 'CEO Kitawa', cmt: 'Là một nhà bán hàng quản lý hàng trăm của hàng Lazada,Shopee, tôi rất đau đầu với việc đối soát hàng hoàn, tài chính. Từ khi sử dụng Easysell.vn, tôi như được giải thoát khỏi gánh nặng!'}, {avatar: '/img/avatar.png', name: 'Võ Công Cao', position: 'CEO Hakawa', cmt: 'Việc quản lý tồn kho, đồng bộ kho, báo cáo tài chính là trăn trở của hàng trăm doanh nghiệp. Với Easysell tôi không còn lo lắng gì nữa, thật tuyệt vời!'}],
        active_comment: 0,
        size: {},
        drawer: false,
        hash: 'home'
      }
    },
    created() {
      this.size = {x: screen.width, y: screen.height}
    },

    mounted() {
      var vm = this;
      timeout = setTimeout(() => {
        vm.active_comment ++;
        if (vm.active_comment > 2)
          vm.active_comment = 0;
      }, 5000);
    },

    methods: {
      onScroll(e) {
        if (window.pageYOffset > document.getElementById('contact').offsetTop - 500) {
          if (this.hash != "contact") {
            this.hash = "contact";
          // return this.$router.push({hash: 'contact'});
          }
          return;
        }
        if (window.pageYOffset > document.getElementById('vision').offsetTop - 300) {
          if (this.hash != "vision") {
            this.hash = "vision";
          // return this.$router.push({hash: 'vision'});
          }
          return;
        }
        if (window.pageYOffset > 0) {
          if (this.hash != "home") {
            this.hash = 'home';
          // return this.$router.push({hash: ''});
          }
          
          return;
        }
      },
      setWidthTopImage() {
        if (this.size.x > 1700) return 1000
        if (this.size.x > 1500) return 770;
        if (this.size.x > 1000) return 660;
      },
      setWidthImage2() {
        if (this.size.x > 1700) return 914
        if (this.size.x > 1500) return 770;
        if (this.size.x > 1000) return 660;
      },
      setWidthSheet4() {
        if (this.size.x > 1700) return 1000
        if (this.size.x > 1400) return 900;
        if (this.size.x > 1000) return 800;
      }
    },

    watch: {
      'active_comment': function() {
        let vm = this;
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          vm.active_comment ++;
          if (vm.active_comment > 2)
            vm.active_comment = 0;
        }, 5000);
      }
    }
  }
</script>
