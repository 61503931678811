<template>
  <v-app v-scroll="onScroll">
    <router-view></router-view>

  </v-app>
</template>


<script>
var timeout;
export default {
   methods: {
      onScroll(e) {
        if (window.pageYOffset > document.getElementById('contact').offsetTop - 500) {
          if (this.hash != "contact") {
            this.hash = "contact";
          // return this.$router.push({hash: 'contact'});
          }
          return;
        }
        if (window.pageYOffset > document.getElementById('vision').offsetTop - 300) {
          if (this.hash != "vision") {
            this.hash = "vision";
          // return this.$router.push({hash: 'vision'});
          }
          return;
        }
        if (window.pageYOffset > 0) {
          if (this.hash != "home") {
            this.hash = 'home';
          // return this.$router.push({hash: ''});
          }
          
          return;
        }
      },
      setWidthTopImage() {
        if (this.size.x > 1700) return 1000
        if (this.size.x > 1500) return 770;
        if (this.size.x > 1000) return 660;
      },
      setWidthImage2() {
        if (this.size.x > 1700) return 914
        if (this.size.x > 1500) return 770;
        if (this.size.x > 1000) return 660;
      },
      setWidthSheet4() {
        if (this.size.x > 1700) return 1000
        if (this.size.x > 1400) return 900;
        if (this.size.x > 1000) return 800;
      }
    },
}
</script>

<style lang="scss">
a {
  text-decoration: none !important;
  color: #000 !important;
}

.pointer {
  cursor: pointer;
}

.menu-item {
    padding: 0 8px 0 8px;
    line-height: 64px;
    div {
      font-weight: 600;
    }
    cursor: pointer;
    &:hover {
    }
}

::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #888;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #0EC7CF;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.active-exact-link {
  color: #0EC7CF !important;
}

.main-title {
  font-size: 50px;
  font-weight: 1000;
  color: #0EC7CF;
}
.text-description {
  font-size: 20px; 
  font-weight: 400;
}

button {
  font-weight: 600;
  font-size: 20px;
  text-transform: capitalize !important;
}

.fade-in-2 {
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
  -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera < 12.1 */
  animation: fadein 2s;
}

.fade-in-4 {
  -webkit-animation: fadein 4s; /* Safari, Chrome and Opera > 14.1 */
  -moz-animation: fadein 4s; /* Firefox < 16 */
  -ms-animation: fadein 4s; /* Internet Explorer */
  -o-animation: fadein 4s; /* Opera < 14.1 */
  animation: fadein 4s;
}

.fade-in-6 {
  -webkit-animation: fadein 6s; /* Safari, Chrome and Opera > 16.1 */
  -moz-animation: fadein 6s; /* Firefox < 16 */
  -ms-animation: fadein 6s; /* Internet Explorer */
  -o-animation: fadein 6s; /* Opera < 16.1 */
  animation: fadein 6s;
}

.fade-in-8 {
  -webkit-animation: fadein 8s; /* Safari, Chrome and Opera > 18.1 */
  -moz-animation: fadein 8s; /* Firefox < 16 */
  -ms-animation: fadein 8s; /* Internet Explorer */
  -o-animation: fadein 8s; /* Opera < 18.1 */
  animation: fadein 8s;
}

.fade-in-10 {
  -webkit-animation: fadein 10s; /* Safari, Chrome and Opera > 110.1 */
  -moz-animation: fadein 10s; /* Firefox < 16 */
  -ms-animation: fadein 10s; /* Internet Explorer */
  -o-animation: fadein 10s; /* Opera < 110.1 */
  animation: fadein 10s;
}

.fade-in {
  -webkit-animation: fadein 3s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 3s; /* Firefox < 16 */
  -ms-animation: fadein 3s; /* Internet Explorer */
  -o-animation: fadein 3s; /* Opera < 12.1 */
  animation: fadein 3s;
}

.fade-out {
  animation: fadeout 3s;
}

.cmt-animation {
  animation: cmtanimation 2s;
}

@keyframes fadeout {
  from { opacity: 1; }
  to   { opacity: 0; }
}

@keyframes cmtanimation {
  from { width: 300px; }
  to   { width: 900px; }
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}
/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}



@media only screen and (max-width: 768px) {
  @keyframes cmtanimation {
    from { width: 300px; }
    to   { width: 100%; }
  }

  .main-title {
   font-size: 28px;
  }
  .text-description {
    font-size: 18px; 
  }
}
</style>
