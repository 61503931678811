<template>
  
    <v-stage ref="stage" :config="configKonva">
        <v-layer ref="layer">
            
            <v-group ref="group" :config="{x: configKonva.width / 2, y: configKonva.height / 2}">
                <v-circle ref="circle" :config="{x: 0, y: 0, radius: w / 2 + w / 20, fill: 'red', stroke: 'red', strokeWidth: 1}">
                </v-circle>
          
            
                <v-group v-for="r, i in rewards" :config="{rotation: r.rotation}" :title="r.title">
                    
                    <v-wedge :config="{
                        lineJoin: 'round',
                        radius: r.radius,
                        rotation: 0,
                        text: r.text,
                        id: r.id,
                        fill: r.active ? 'red' : r.fill,
                        angle: r.angle}">

                    </v-wedge>
                </v-group>

                <v-group v-for="r, i in rewards" :config="{rotation: r.rotation + rot / 2}">
                    <v-text :config="{id: r.id, text: r.text, fontSize: 20 , x: w / 2 , rotation: 177, offsetX: -30}"></v-text>
                </v-group>
                

                

                <v-circle v-for="i in [0, w/2 + w/40, - (w/2 + w/40), w/2, -w/2, w/3, -w/3, w/4, -w/4, w/2.2, -w/2.2]" :config="{x: i, y: ptdt(i, w/2 + w/40), radius: w/60, fill: 'white'}"></v-circle>    
                <v-circle v-for="i in [0, w/2 + w/40, - (w/2 + w/40), w/2, -w/2, w/3, -w/3, w/4, -w/4, w/2.2, -w/2.2]" :config="{x: i, y: ptdtAm(i, w/2 + w/40), radius: w/60, fill: 'white'}"></v-circle>   

                <!-- <v-circle v-for="i in [-180, -150, - 120, -90, -60, -30, 0, 30, 60, 90, 120, 150, 180]" :config="{x: i, y: ptdtAm(i, 315), radius: 10, fill: 'white'}"></v-circle>                        -->


                <v-group @click="wheel" @tap="wheel">
                    <v-circle :config="{
                        x: 0, y: 0, radius: 50,
                        fillRadialGradientStartRadius: 0,
                        fillRadialGradientEndRadius: 150,
                        fillRadialGradientColorStops: [0, 'green', 1, 'red'],
                    }">
                        
                    </v-circle>

                    <v-text  :config="{text: 'QUAY', fill: 'yellow', fontSize: 20, x: -28, y: -10}">

                    </v-text>
                </v-group>
            </v-group>

            <v-wedge :config="{radius: w / 12, 
                    lineJoin: 'round', 
                    angle: 50, x: 500, y: 100,
                    stroke: 'white', rotation: -70,
                    strokeWidth: 5,
        
                    fillRadialGradientStartRadius: 0,
                    fillRadialGradientEndRadius: 50,
                    fillRadialGradientColorStops: [0, 'black', 1, 'green'],
                    strokeWidth: 2 }">
            </v-wedge>

            
        </v-layer>
    </v-stage>
           
</template>

<script>

import Konva from "konva";

var vm;

export default {
    props: {
        luckyDraws: [], startWheel: {}, reward_id: {}
    },
    data() {
        return {
            w: 600,

            configKonva: null,
            configCircle: null,
            rewards: [
                
            ],
            
            dive: 8,
            makeColors: [],
            rot: 20,
            angularVelocity : 12,
            group: null,
            layer: null,
            stage: null,
            anim: null,
            id_reward_user_get: 1,
            reward_active: null,
            isWheel: false,
            timeDiff: 0,
            isStop: false,
            angularStop: 0,
            stopRotation: 0,
            stopingRotation: 0,
            dis_sound: new Audio('/img/disconnect.mp3'),
            done_sound: new Audio('/img/bell_ring.mp3')
        }
    },


    created() {
        vm = this;


        vm.initKonvas();

        vm.dive = vm.luckyDraws.data.length;

        let m = vm.dive;

        let rot = 360 / m;

        vm.rot = rot;

        let colors = ['#cdeb0c', '#cf9102']

        for (let i in vm.luckyDraws.data) {
            vm.rewards.push({id: vm.luckyDraws.data[i].id,
                    text: vm.luckyDraws.data[i].text, 
                    title: vm.luckyDraws.data[i].title,
                    rotation: i * rot,
                    angle: rot,
                    fill: colors[i % 2],
                    radius: vm.w / 2});
        }

    },

    mounted() {
        
    },
    
    methods: {
        initKonvas() {
            vm.configKonva = {
                width: 700,
                height: 700,
            };  
        },
        animate(frame) {
            vm.group.rotate(vm.angularVelocity)
            var shape = vm.stage.getIntersection({
                x: 500,
                y: 110,
            });

           // console.log('s', shape.id());
            var id = shape.id();
            //var id = shape.getParent().findOne('Wedge').id();
            vm.reward_active = id;
            if (vm.reward_id == vm.reward_active && !vm.isStop) {
                vm.stopRotation = vm.group.rotation() 
                vm.stopWheel();
                
            }
            if (vm.isStop) {
                vm.stopingRotation = vm.group.rotation();
                if (vm.angularVelocity > 10) {
                    vm.angularVelocity -= 0.1;
                }
                else if (vm.angularVelocity > 5) {
                    vm.angularVelocity -= 0.1;
                }
                else if (vm.angularVelocity > 3) {
                    vm.angularVelocity -= 0.05;
                }
                else if (vm.angularVelocity > 2) {
                    vm.angularVelocity -= 0.03;
                }
                else if (vm.angularVelocity > 1) {
                    vm.angularVelocity -= 0.01;
                }
                else if (vm.angularVelocity > 0.75)
                    vm.angularVelocity -= 0.006;
                else if (vm.angularVelocity > 0.5)
                    vm.angularVelocity -= 0.005;
                else if (vm.angularVelocity > 0.3)
                    vm.angularVelocity -= 0.003;
                if (vm.stopingRotation >= vm.stopRotation + 356 * 3 && vm.reward_id == vm.reward_active) {
                    vm.done_sound.play();
                    console.log('done wheel');
                    vm.$emit('got_wheel');
                    vm.anim.stop();
                    vm.isStop = true;
                    vm.isWheel = false;
                    vm.stopRotation = 0;
                    vm.stopingRotation = 0;
                    vm.angularVelocity = 12;
                }
            }
            
        },

        wheel() {
            if (vm.isWheel) return;

            if (!vm.stage) {
                vm.stage = vm.$refs.stage.getStage();
                vm.group = vm.$refs.group.getStage();
                vm.layer = vm.$refs.layer.getStage();
            }

            vm.reward_id = null;

            if (vm.startWheel()) {
                vm.group.rotation(0);
                vm.isWheel = true;
                vm.isStop = false;
                if (!vm.anim)
                    vm.anim = new Konva.Animation(vm.animate, vm.layer);
                vm.anim.start();
            }
            
            
        },

        stopWheel() {
            vm.isStop = true;
            let stopTime = 5 * 1000;
            let frameCount = stopTime / vm.timeDiff;
            let stopRotate = 380;

           // vm.angularVelocity = frameCount / stopRotate;
        },

        ptdt(x, R) {
            return Math.sqrt(R*R - x*x);
        },

        ptdtAm(x, R) {
            return - Math.sqrt(R*R - x*x);
        }
    },

    watch: {
        reward_active: function() {
            for (let r of vm.rewards) {
                if (r.id == vm.reward_active) {
                    r.active = true;
                }
                else {
                    r.active = false;
                }
            }
            vm.dis_sound.play();
            vm.$forceUpdate();
        },

        reward_id: function() {
            console.log('rewards', vm.reward_id)
            vm.id_reward_user_get = vm.reward_id;
        }
    }
}
</script>
