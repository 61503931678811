var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-stage',{ref:"stage",attrs:{"config":_vm.configKonva}},[_c('v-layer',{ref:"layer"},[_c('v-group',{ref:"group",attrs:{"config":{x: _vm.configKonva.width / 2, y: _vm.configKonva.height / 2}}},[_c('v-circle',{ref:"circle",attrs:{"config":{x: 0, y: 0, radius: _vm.w / 2 + _vm.w / 20, fill: 'red', stroke: 'red', strokeWidth: 1}}}),_vm._l((_vm.rewards),function(r,i){return _c('v-group',{attrs:{"config":{rotation: r.rotation},"title":r.title}},[_c('v-wedge',{attrs:{"config":{
                    lineJoin: 'round',
                    radius: r.radius,
                    rotation: 0,
                    text: r.text,
                    id: r.id,
                    fill: r.active ? 'red' : r.fill,
                    angle: r.angle}}})],1)}),_vm._l((_vm.rewards),function(r,i){return _c('v-group',{attrs:{"config":{rotation: r.rotation + _vm.rot / 2}}},[_c('v-text',{attrs:{"config":{id: r.id, text: r.text, fontSize: 20 , x: _vm.w / 2 , rotation: 177, offsetX: -30}}})],1)}),_vm._l(([0, _vm.w/2 + _vm.w/40, - (_vm.w/2 + _vm.w/40), _vm.w/2, -_vm.w/2, _vm.w/3, -_vm.w/3, _vm.w/4, -_vm.w/4, _vm.w/2.2, -_vm.w/2.2]),function(i){return _c('v-circle',{attrs:{"config":{x: i, y: _vm.ptdt(i, _vm.w/2 + _vm.w/40), radius: _vm.w/60, fill: 'white'}}})}),_vm._l(([0, _vm.w/2 + _vm.w/40, - (_vm.w/2 + _vm.w/40), _vm.w/2, -_vm.w/2, _vm.w/3, -_vm.w/3, _vm.w/4, -_vm.w/4, _vm.w/2.2, -_vm.w/2.2]),function(i){return _c('v-circle',{attrs:{"config":{x: i, y: _vm.ptdtAm(i, _vm.w/2 + _vm.w/40), radius: _vm.w/60, fill: 'white'}}})}),_c('v-group',{on:{"click":_vm.wheel,"tap":_vm.wheel}},[_c('v-circle',{attrs:{"config":{
                    x: 0, y: 0, radius: 50,
                    fillRadialGradientStartRadius: 0,
                    fillRadialGradientEndRadius: 150,
                    fillRadialGradientColorStops: [0, 'green', 1, 'red'],
                }}}),_c('v-text',{attrs:{"config":{text: 'QUAY', fill: 'yellow', fontSize: 20, x: -28, y: -10}}})],1)],2),_c('v-wedge',{attrs:{"config":{radius: _vm.w / 12, 
                lineJoin: 'round', 
                angle: 50, x: 500, y: 100,
                stroke: 'white', rotation: -70,
                strokeWidth: 5,
    
                fillRadialGradientStartRadius: 0,
                fillRadialGradientEndRadius: 50,
                fillRadialGradientColorStops: [0, 'black', 1, 'green'],
                strokeWidth: 2 }}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }